<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col cols="10" sm="8" md="6" lg="4">

            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <!-- TODO: show the cancel button differently, and make it clear that it empties the entire cart ... and not just goes back to last page... maybe there need to be separate buttons for these two things, properly labeled -->
            <template v-if="isViewReady">
                <CheckoutProgress step="finalize" class="mb-6"/> <!--  :shipping="input.require_shipping" -->
            </template>
            <v-card v-if="isViewReady && cart">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Finalize your order</v-app-bar-title>
                </v-app-bar>
                <v-card-text>
                <v-simple-table class="finalize-order-form">
                    <template #default>
                        <tbody>
                            <tr>
                                <!-- TODO this should be a SUMMARY of what was already selected, with a button to go back to checkout-account step if user wants to make any changes; the inputs will be in checkout-account, not here -->
                                <th>Account</th>
                                <td>
                                    <CheckoutSelectAccount @selected="selectAccount"></CheckoutSelectAccount>
                                </td>
                            </tr>
                            <!-- A user who already has a profile doesn't need to enter name or email address, we already have it; another UI element could let the user sign out and either complete the order as a guest or sign in as someone else -->
                            <tr v-if="(cart.require.require_name || signup) && !user">
                                <th>Name</th>
                                <td><v-text-field v-model="name" :error-messages="inputError.name" dense solo/></td>
                            </tr>
                            <tr v-if="(cart.require.require_email || signup) && !user">
                                <th>Email</th>
                                <td><v-text-field v-model="email" :error-messages="inputError.email" dense solo/></td>
                                <!-- TODO: explain why we need this -- to send receipt, to send delivery notification, etc. we can show the reasons based on the require_* items , or maybe server could also provide reason codes for us to display-->
                            </tr>
                            <!-- TODO: since shipping was already enetered in a previous step, and some fees may have been set based on the shipping address,  this should be READ ONLY  and not editable -->
                            <tr v-if="cart.require.require_shipping">
                                <th>Shipping address</th>
                                <td>
                                    <PostalAddressForm v-model="shippingAddress"/>
                                    <p class="text-caption red--text">&nbsp;
                                        <span v-if="inputError.shipping_address">{{inputError.shipping_address}}</span>
                                    </p>
                                </td>
                                <!-- TODO: explain why we need this -- because of recurring billing, subscription, or membership , that we might need to send an invoice or have an address of record -->
                            </tr>
                            <!-- TODO: add a checkbox  "same as shipping address" ONLY IF input.require_shipping is true ; default should be CHECKED  and then user can uncheck it and enter a different billing address if they want -->
                            <tr v-if="cart.require.require_billing">
                                <th>Billing address</th>
                                <td>
                                    <PostalAddressForm v-model="billingAddress"/>
                                    <p class="text-caption red--text">&nbsp;
                                        <span v-if="inputError.billing_address">{{inputError.billing_address}}</span>
                                    </p>
                                </td>
                                <!-- TODO: explain why we need this -- because of recurring billing, subscription, or membership , that we might need to send an invoice or have an address of record -->
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>
                <!-- TODO: subtotal, shipping/taxes, total -->
                <v-card-actions>
                    <v-spacer/>
                    <v-btn :style="primaryButtonStyle" @click="submit">Continue</v-btn>
                </v-card-actions>
            </v-card>
            <!-- TODO:  if there's an error loading the cart, show temporary error: -->
            <v-card elevation="2" v-if="isViewReady && !cart">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Maintenance</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>The server is temporarily unavailable.</p>
                    <!-- TODO: We are going to automatically retry until it's ready. Please wait... -->
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* remove messages area from inputs */
.v-input.v-input--checkbox.hide-messages .v-messages {
    display: none;
}
/* align table headings and content to top */
.finalize-order-form th {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
.finalize-order-form td {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { toMillis } from '@libertyio/time-util-js';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
// import { loadStripe } from '@stripe/stripe-js';
import { isValidEmail } from '@/sdk/input';
import CheckoutProgress from '@/components/CheckoutProgress.vue';
import PostalAddressForm from '@/components/PostalAddressForm.vue';
import CheckoutSelectAccount from '@/components/CheckoutSelectAccount.vue';

function isValidPostalAddress(address) {
    let error = false;
    ['name', 'line1', 'city', 'state', 'code', 'country'].forEach((attr) => {
        if (!address[attr]) {
            error = true;
        }
    });
    // TODO: validate country value (should be an ISO two or three letter code)
    // TODO: validate state name or abbrev (should be a standard name or abbrev , depends on country)
    // TODO: validate postal code (depends on coutnry)
    return !error;
}

export default {
    components: {
        CheckoutProgress,
        PostalAddressForm,
        CheckoutSelectAccount,
    },
    data: () => ({
        // TODO: all the stuff below is also in the cart... do we need to copy it to these variables after we load the cart? or are these the editable varaibles and  cart.X is the last status from server we can use for display?
        name: null,
        email: null,
        signup: null,
        billingAddress: {
            name: null,
            line1: null,
            line2: null,
            line3: null,
            line4: null,
            line5: null,
            city: null,
            state: null,
            code: null,
            country: null,
        },
        shippingAddress: {
            name: null,
            line1: null,
            line2: null,
            line3: null,
            line4: null,
            line5: null,
            city: null,
            state: null,
            code: null,
            country: null,
        },
        addressList: [],
        isViewReady: false,
        submitTimestamp: null,
        inputError: {
            name: null,
            email: null,
            billing_address: null,
            shipping_address: null,
        },
        inputErrorTimeout: {
            name: null,
            email: null,
            billing_address: null,
            shipping_address: null,
        },
        // mode: 'prompt', // 'prompt' shows line items and continue button, 'email' prompts for email
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            user: (state) => state.user,
            account: (state) => state.account,
            accountList: (state) => state.accountList,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
            cart: (state) => state.cart,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        searchLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'search', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'search' };
            }
            return link;
        },
        checkoutAccountLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-account', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-account' };
            }
            return link;
        },
        paymentLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-payment', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-payment' };
            }
            return link;
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.finalize();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
        name(newValue, oldValue) {
            if (this.billingAddress?.name === null || this.billingAddress.name.length === 0 || this.billingAddress.name === oldValue) {
                this.$set(this.billingAddress, 'name', newValue);
            }
            if (this.shippingAddress?.name === null || this.shippingAddress.name.length === 0 || this.shippingAddress.name === oldValue) {
                this.$set(this.shippingAddress, 'name', newValue);
            }
        },
    },
    methods: {
        async init() {
            await this.$store.dispatch('loadCart');
            if (this.cart && this.isAuthenticated && this.user) {
                if (this.cart.require.require_name && !this.name && this.user.name) {
                    this.name = this.user.name;
                }
                if (this.cart.require.require_email && !this.email && this.user.email) {
                    this.email = this.user.email;
                }
            }
            await this.prepareView();
        },
        async finalize() {
            try {
                this.error = false;
                // 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded'
                this.$store.commit('loading', { finalize: true });

                const order = {
                    name: this.name,
                    email: this.email ?? this.user.email,
                    // signup: this.signup, // TODO: so this is a checkbox for OPTIONAL signup, it doesn't actually fill the 'require_signup' thing becuase that needs user to actually sign up before completing the order;  so we need to rename this from 'signup' to 'send_email_with_signup_link_after_order' or something like that.
                    billing_address: this.billingAddress,
                    shipping_address: this.shippingAddress,
                };
                const response = await this.$client.site(this.brandprofile).cart.finalize(order);
                if (response) {
                    this.$store.commit('cart', response);
                    if (response.status === 'error' && response.error) {
                        switch (response.error.check) {
                        case 'limit_one_per_profile': {
                            switch (response.error.fault) {
                            case 'activated_in_other_account': {
                                // TODO: show this in an alert in the UI instead of a snackbar, and if variants of this item are available to upgradse/downgrade maybe prompt user if that's what they were trying to do,  because they can't get another of the same service in the same account, and they're not allowed to get the same service in any other account either (typical for free service)
                                const accountNameCSV = response.error.account_list.map((accountItem) => accountItem.name).join(', ');
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Limit one per customer', message: `You already got this: ${accountNameCSV}` });
                                break;
                            }
                            case 'email_required': {
                                // TODO: this means the server can't check the limit_one_per_profile rule because it doens't know which profile to check; but we shouldn't get here without an email address -- the service should have required it, or user should be authenticated already; in any case if we get this error we need to show the email input field and get the user's email address, then resubmit the finalize API request
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Email is required' });
                                break;
                            }
                            default: {
                                this.$bus.$emit('snackbar', { type: 'error', headline: 'Limit one per customer', message: response.error.fault });
                            }
                            }
                            break;
                        }
                        default:
                            this.$bus.$emit('snackbar', { type: 'error', headline: 'An error occurred', message: `${response.error.check}: ${response.error.fault}` });
                        }
                    }
                } else {
                    this.$store.commit('cart', null);
                }
            } catch (err) {
                console.error('finalize failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { finalize: false });
                this.prepareView();
            }
        },
        async submit() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            let error = false;
            this.submitTimestamp = Date.now();
            if (this.cart.require.require_name && !this.name) {
                this.inputError.name = 'Please enter your full name or company name';
                if (this.inputErrorTimeout.name) {
                    clearTimeout(this.inputErrorTimeout.name);
                }
                this.inputErrorTimeout.name = setTimeout(() => { this.$set(this.inputError, 'name', null); }, toMillis({ seconds: 10 }));
                error = true;
            }
            if (this.cart.require.require_email && !isValidEmail(this.email)) {
                this.inputError.email = 'Please enter an email address';
                if (this.inputErrorTimeout.email) {
                    clearTimeout(this.inputErrorTimeout.email);
                }
                this.inputErrorTimeout.email = setTimeout(() => { this.$set(this.inputError, 'email', null); }, toMillis({ seconds: 10 }));
                error = true;
            }
            if (this.cart.require.require_billing && !isValidPostalAddress(this.billingAddress)) {
                this.inputError.billing_address = 'Please enter a billing address';
                if (this.inputErrorTimeout.billing_address) {
                    clearTimeout(this.inputErrorTimeout.billing_address);
                }
                this.inputErrorTimeout.billing_address = setTimeout(() => { this.$set(this.inputError, 'billing_address', null); }, toMillis({ seconds: 10 }));
                error = true;
            }
            if (this.cart.require.require_shipping && !isValidPostalAddress(this.shippingAddress)) {
                this.inputError.shipping_address = 'Please enter a shipping address';
                if (this.inputErrorTimeout.shipping_address) {
                    clearTimeout(this.inputErrorTimeout.shipping_address);
                }
                this.inputErrorTimeout.shipping_address = setTimeout(() => { this.$set(this.inputError, 'shipping_address', null); }, toMillis({ seconds: 10 }));
                error = true;
            }
            // if user is authenticated and they haven't selected an account yet, they need to select one before continuing
            if (this.isAuthenticated && !this.cart.account_id) {
                this.$bus.$emit('snackbar', { type: 'warn', headline: 'Please select an account first' });
                return;
            }
            // if user is not authenticated and they haven't created a guest account yet, do that now
            if (!this.isAuthenticated && !this.cart.account_id) {
                try {
                    const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'guest_account' });
                    console.log(`Finalize.vue created guest account; response ${JSON.stringify(response)}`);
                } catch (err) {
                    console.error('Finalize.vue failed to create guest account', err);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'There is a problem with this order', message: 'Please contact customer support' });
                    return;
                }
            }
            if (!error) {
                await this.finalize();
                if (this.cart?.status === 'ready') {
                    // if payment is due, redirect to payment; otherwise, skip payment and go directly to receipt
                    const total = Array.isArray(this.cart.totals) && this.cart.totals.length > 0 ? this.cart.totals[0] : null;
                    if (total?.amount_csu > 0) {
                        this.$nav.push(this.paymentLink);
                    } else {
                        try {
                            // even for a free order we call the payment API, and if the total is zero it will activate the order immediately
                            const paymentResponse = await this.$client.site(this.brandprofile).cart.payment(/* { email: this.email } */);
                            if (paymentResponse.order_id) {
                                this.orderId = paymentResponse.order_id;
                            }
                            if (paymentResponse.order_status) {
                                this.orderStatus = paymentResponse.order_status;
                            }

                            if (['paid', 'activated', 'processed'].includes(paymentResponse.order_status)) {
                                this.$router.replace(this.receiptLink(this.orderId));
                                return;
                            }

                            console.log(`Finalize.vue: submit total due zero but payment API returned unexpected response: ${JSON.stringifY(paymentResponse)}`);
                        } catch (err) {
                            console.error('Finalize.vue failed to start payment', err);
                            this.$bus.$emit('snackbar', { type: 'error', headline: 'There is a problem with this order', message: 'Please contact customer support' });
                            /*
                            if (err.response.status === 409) {
                                // console.log(`Finalize.vue response conflict data: ${JSON.stringify(err.response.data)}`);
                                const responseBody = err.response.data;
                                if (responseBody.type === 'fault' && responseBody.data?.precondition === 'select_account') {
                                    //
                                }
                            }
                            */
                        }
                    }
                }
            }
        },
        receiptLink(orderId) {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-receipt', params: { brandprofile: this.brandprofile }, query: { order_id: orderId } };
            } else {
                link = { name: 'main-receipt', query: { order_id: orderId } };
            }
            return link;
        },
        formatAmount(currency, amount) {
            const price = fromCurrencyAmountCSU(currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', { // TODO: localization, if we know user's locale use that instead of en-US
                currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        // call after loadCart or finalize to process the results
        async prepareView() {
            try {
                if (!this.cart) {
                    this.error = true;
                    return;
                }
                if (!['pending', 'finalize', 'ready'].includes(this.cart.status)) {
                    this.error = true; // TODO: maybe redirect user to the appropriate step of the process instead of showing an error? they might have used their back button.... we can use .replace to replace this page with the right one like receipt, so from there if they go back its to the cart and not back here.
                    return;
                }
                if (this.cart.name && !this.name) {
                    this.name = this.cart.name;
                }
                if (this.cart.email && !this.email) {
                    this.email = this.cart.email;
                }
                if (this.cart.billing_address && !this.billingAddress) {
                    this.billingAddress = this.cart.billing_address;
                }
                if (this.cart.shipping_address && !this.shippingAddress) {
                    this.shippingAddress = this.cart.shipping_address;
                }

                if (this.cart.account_id && !this.account) {
                    await this.loadAccount(this.cart.account_id);
                }
            } catch (err) {
                console.error('prepareView failed', err);
            } finally {
                this.isViewReady = true;
            }
        },
        selectAccount(accountId) {
            if (this.$route.query.accountId !== accountId) {
                this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, accountId, t: Date.now() } });
            }
        },
        async loadAccount(accountId) {
            await this.$store.dispatch('loadAccount', { accountId });
            if (this.account) {
                this.$nav.set('account_id', this.account.id);
            }
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
